import PropTypes from "prop-types";
import "./Section.css";

const Section = ({ className = "" }) => {
  return (
    <div className={`custom-test ${className}`}>
      <div className="custom-test-features">
        <div className="heading">
          <img
            className="custom-test-icon"
            alt=""
            src={"/custom-test-icon.svg"}
          />
          <div className="create-your-own-container">
            <b>{`Create Your Own Custom Test `}</b>
            <i className="with-10000-questions">{`with 10,000+ Questions `}</i>
          </div>
        </div>
        <div className="features">
          <div className="parent">
            <div className="div">•</div>
            <div className="create-exam-wise-custom">{`Create custom tests for  ${
              window.location.host?.toLowerCase()?.includes("ssc")
                ? "CGL, CHSL, MTS & CPO."
                : window.location.host?.toLowerCase()?.includes("rrb")
                ? "RRB NTPC"
                : window.location.host?.toLowerCase()?.includes("gate")
                ? "GATE CSE"
                : "Bank PO"
            }`}</div>
          </div>
          <div className="group">
            <div className="div">•</div>
            <div className="evaluate-your-strengths">
              Evaluate your strengths and weaknesses
            </div>
          </div>
          <div className="group">
            <div className="div">•</div>
            <div className="evaluate-your-strengths">
              Get in-depth analysis of your custom tests
            </div>
          </div>
        </div>
      </div>
      <div className="custom-test-image">
        <img
          className="custom-test-image1"
          alt=""
          src={
            window.location.host?.toLowerCase()?.includes("ssc")
              ? "/custom-test-image@2x.png"
              : window.location.host?.toLowerCase()?.includes("ibps")
              ? "/ibps_custom_Test.png"
              : window.location.host?.toLowerCase()?.includes("gate")
              ? "/gate_cse_custom_Test.png"
              : "/rrb_custom_Test.png"
          }
        />
      </div>
    </div>
  );
};

Section.propTypes = {
  className: PropTypes.string,
};

export default Section;
