import React from "react";
import he from "he";
import { MathJax } from "better-react-mathjax";
function HTMLMathjaxRender({ text }) {
  const decodedHtml = he.decode(text);
  return (
    <div>
      <MathJax dynamic>
        <div
          dangerouslySetInnerHTML={{
            __html: decodedHtml,
          }}
        ></div>
      </MathJax>
    </div>
  );
}

export default HTMLMathjaxRender;
