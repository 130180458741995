import React, { useEffect, useState } from "react";
import "./style/practiceHub.css";
import getPracticeHubData from "./api/getPracticeHubData";
import { useNavigate } from "react-router-dom";
import { MathJax } from "better-react-mathjax";
import getAndInsertQuestionsSelected from "./api/getAndInsertQuestionsSelected";
import SyncLoader from "react-spinners/SyncLoader";
import "../chapter/styles/question-body.css";
import sign from "jwt-encode";
import getPremiumData from "../navbar/getPremiumData";
import { arrowDownIcon, collapseIcon, expandIcon } from "../../assets/icons";
import HTMLMathjaxRender from "../chapter/HTMLMathjaxRender";
function PracticeHub({ UserID, subjectualData }) {
  const [Subjects, setSubjects] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [hasPremium, setHasPremium] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   setInterval(() => {}, [1000]);
  // }, []);

  useEffect(() => {
    const payloadieee = {
      userid: UserID,
      // amount: 100,
      // category: "PaymentProceeded",
      // duration: 30,
      usage_count: 2,
      role: "admin",
      exp: Math.floor(Date.now() / 1000) + 1800, //1800 sec
      // ... other claims or data
    };
    const secretKeyiieee = "22115s5f5a5fafa";
    const tokeniieee = sign(payloadieee, secretKeyiieee);
    if (UserID) {
      getPremiumData(
        tokeniieee +
          ("&location=" + location.pathname.toString()) +
          ("&user=" + UserID) +
          "&paymentClicked=yes"
      )
        .then((res) => {
          setHasPremium(
            !(
              window.location.host?.toLowerCase()?.includes("ssc") ||
              window.location.host?.toLowerCase()?.includes("gate") ||
              window.location.host?.toLowerCase()?.includes("ibps")
            ) ||
              res.data?.filter((obj) => obj?.Category === "TestSeriesNioclass")
                .length
              ? true
              : false
          );
        })
        .catch((err) => console.log(err));
    }
  }, [UserID]);

  return (
    <div className="maiContainer">
      <div className="headerSystem">
        <div className="HeaderText">
          <div className="headerDetails">Practice HUB</div>
          <div className="headerDetailsSub">
            Select ypur Subject,Chapter, Questions which you want to practice
          </div>
        </div>
        {/* <button className="app-btn-1">Go To TestSeries</button> */}
      </div>
      {/* <div className='filterBars'>
            <div className='filtercontainers'>
                Select Subject
                <span>
                    ^
                </span>

            </div>
        </div> */}
      <FilterAndQuestionSelection
        UserID={UserID}
        questions={questions}
        setQuestions={setQuestions}
        navigate={navigate}
        hasPremium={hasPremium}
      />
    </div>
  );
}

export default PracticeHub;

const FilterAndQuestionSelection = ({
  UserID,
  questions,
  setQuestions,
  navigate,
  hasPremium,
}) => {
  // Initial state for filters
  const [filters, setFilters] = useState({
    subject: "",
    chapter: "",
    phase: [],
    year: [],
    attempted: "",
  });

  // State for fetched questions

  const [filteredQuestion, setFilteredQuestion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [pagingQuestions, setPagingQuestions] = useState([]);
  const [pageValue, setPageValue] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [loader, setLoader] = useState(false);
  let questionsPerPage = 7;

  // Simulated data for filters (replace with actual API calls if needed)
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [phases, setPhases] = useState([]);
  const [years, setYears] = useState([]);
  const [attemptedOptions, setAttemptedOptions] = useState([
    { value: "All", label: "All" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ]);

  useEffect(() => {
    // Calculate total pages based on the filtered questions and questions per page
    const totalQuestions = filteredQuestion.length;
    setTotalPages(Math.ceil(totalQuestions / questionsPerPage));
  }, [filteredQuestion, questionsPerPage]);

  // Load questions for the current page
  useEffect(() => {
    const startIdx = (currentPage - 1) * questionsPerPage;
    const selectedQuestions = filteredQuestion.slice(
      startIdx,
      startIdx + questionsPerPage
    );
    setPagingQuestions(selectedQuestions);
  }, [currentPage, filteredQuestion, questionsPerPage]);

  // Function to go to a specific page
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Function to get the pagination range with ellipsis
  const getPaginationRange = () => {
    const pages = [];
    let showPiece = window?.innerWidth > 600.02 ? 2 : 1;
    let startPage = Math.max(1, currentPage - showPiece);
    let endPage = Math.min(totalPages, currentPage + showPiece);

    // Add previous ellipsis
    if (startPage > 1) {
      // pages.push("<<");
      // pages.push("<");
    }

    // Add page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add next ellipsis
    if (endPage < totalPages) {
      // pages.push(">");
      // pages.push(">>");
    }

    return pages;
  };

  useEffect(() => {
    getPracticeHubData(
      `Exam=${
        window.location.host?.toLowerCase()?.includes("iit") ? "jee" : "gate"
      }`
    )
      .then((res) => {
        setSubjects(res?.data?.Subjects);
        setFilters({
          subject: "",
          chapter: "",
          phase: [],
          year: [],
          attempted: "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (filters?.subject) {
      getPracticeHubData(
        `Exam=${
          window.location.host?.toLowerCase()?.includes("iit") ? "jee" : "gate"
        }&Subject=${filters?.subject}`
      )
        .then((res) => {
          setChapters(res?.data?.Chapters);
        })
        .catch((err) => console.log(err));
    }
  }, [filters.subject]);
  useEffect(() => {
    if (filters?.chapter) {
      // console.log("Hiii");
      getPracticeHubData(
        `Exam=${
          window.location.host?.toLowerCase()?.includes("iit") ? "jee" : "gate"
        }&Subject=${filters?.subject}&ChapterID=${filters?.chapter}&Phase=1`
      )
        .then((res) => {
          // console.log(res?.data);
          setYears(res?.data?.Year);
          setPhases(res?.data?.Phase);
        })
        .catch((err) => console.log(err));
    }
  }, [filters?.chapter?.length, filters?.subject]);

  const handleFilterChange = async (e) => {
    const { name, value, type, selectedOptions } = e.target;

    // Handle multiple selections for "Year"
    if (name === "year" && type === "select-multiple") {
      const selectedValues = Array.from(selectedOptions)
        .map((option) => option.value)
        .filter((value) => value !== ""); // Get selected year values

      setFilters((prevFilters) => {
        let newYears = [...prevFilters.year];

        // Remove any selected value from the existing years
        selectedValues.forEach((selectedValue) => {
          if (newYears.includes(selectedValue)) {
            newYears = newYears.filter((year) => year !== selectedValue); // Remove if already selected
          } else {
            newYears.push(selectedValue); // Add if not already selected
          }
        });
        const newFilters = { ...prevFilters, [name]: newYears };
        // Automatically trigger fetching questions if subject and chapter are selected
        if (newFilters.subject && newFilters.chapter && !questions.length) {
          fetchQuestions(
            newFilters.subject,
            newFilters.chapter,
            newFilters.year
          );
        }
        return newFilters;
      });
    } else {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [name]: value };
        // Automatically trigger fetching questions if subject and chapter are selected
        if (newFilters.subject && newFilters.chapter) {
          fetchQuestions(
            newFilters.subject,
            newFilters.chapter,
            newFilters.year
          );
        }
        return newFilters;
      });
    }
  };

  useEffect(() => {
    if (questions?.length) {
      setFilteredQuestion((prev) => {
        // If filters are not properly set, return an empty array
        if (
          !(
            filters.phase?.length ||
            filters?.year?.length ||
            filters?.attempted
          )
        ) {
          console.log("stupid");
          return questions;
        }

        // Filter questions based on the provided filter values
        const filteredQuestions = questions.filter((question) => {
          // Check if the filter array has data, then apply the corresponding filter

          const isYearMatch = filters.year.length
            ? filters.year.includes(question.ExamYear)
            : true;
          const isPhaseMatch = filters.phase.length
            ? filters.phase.includes(question.ExamPhase)
            : true;

          // If filters.attempted is set to "All", don't apply the attempted filter
          let isAttemptedMatch = true; // Default to true if filters.attempted is "All"
          if (filters.attempted !== "All" && filters.attempted) {
            isAttemptedMatch = question.Attempted === filters.attempted;
          }

          // Combine all filters only if they have data
          return isYearMatch && isPhaseMatch && isAttemptedMatch;
        });

        return filteredQuestions;
      });
    }
  }, [
    filters.phase?.length,
    filters.year?.length,
    filters.attempted,
    questions,
  ]);

  // console.log(filteredQuestion);

  // Fetch questions based on selected filters
  const fetchQuestions = async (subject, chapter) => {
    getPracticeHubData(
      `Exam=gate&Subject=${subject}&ChapterID=${chapter}&UserID=${UserID}`
    )
      .then((res) => {
        setQuestions(res?.data?.Questions);
        setFilteredQuestion(res?.data?.Questions);
      })
      .catch((err) => console.log(err));
  };

  // Handle single question selection/deselection
  const handleQuestionSelect = (questionId) => {
    if (selectedQuestions.includes(questionId)) {
      setSelectedQuestions((prev) => prev.filter((id) => id !== questionId));
    } else {
      setSelectedQuestions((prev) => [...prev, questionId]);
    }
  };

  // Handle "Select All" action
  const handleSelectAll = () => {
    if (selectedQuestions.length === questions.length) {
      setSelectedQuestions([]);
    } else {
      setSelectedQuestions(questions.map((question) => question.QuestionID));
    }
  };

  // Handle submit
  const handleSubmit = () => {
    setLoader(true);
    let Questions = new Array(...selectedQuestions);
    // console.log("Selected Question IDs:", selectedQuestions);

    let body = {
      QuestionIDs: Questions,
      UserID: UserID,
      QuestionCount: Questions.length,
      IsScheduled: false,
      ScheduleTime: null,
      IsPYQ: false,
      ChapterID: filters.chapter,
      Subject: filters.subject,
      Duration: Questions.length * 2,
    };
    getAndInsertQuestionsSelected("", body)
      .then((res) => {
        let data = JSON.parse(res.data);
        setLoader(false);
        // console.log(data);

        // function responseCreateTest(adata) {
        if (data.Questions?.length > 0 && hasPremium) {
          navigate("/GuidedTest", {
            state: {
              Questions: data.Questions,
              // ChapterID: (chapterSelected?.ChapterID ? chapterSelected?.ChapterID : "All"),
              ChapterID: filters.chapter,
              // ChapterName: (chapterSelected?.ChapterName ? chapterSelected?.ChapterName : "All"),
              ChapterName: filters.subject,
              UserID: { UserID },
              TestID: data.TestID,
            },
          });
        } else if (data.Questions?.length > 0 && !hasPremium) {
          navigate("/paymentTestDetails", {
            state: {
              // TestID: adata.TestID,
              // TimeAlloted:
              //   (timerDurationBox.hour * 60 + timerDurationBox.min) * 60,
              Questions: data.Questions,
              // ChapterID: (chapterSelected?.ChapterID ? chapterSelected?.ChapterID : "All"),
              // ChapterName: (chapterSelected?.ChapterName ? chapterSelected?.ChapterName : "All"),
              // ChapterID: (chapterSelected?.ChapterID ? chapterSelected?.ChapterID : "All"),
              ChapterID: filters.chapter,
              // ChapterName: (chapterSelected?.ChapterName ? chapterSelected?.ChapterName : "All"),
              ChapterName: filters.subject,
              UserID: { UserID },
              TestID: data.TestID,
              navigationPlat: "/GuidedTest",
              navigatedFrom: location.pathname,
            },
          });
        } else {
          setLoader(false);
          alert("please select or add another chapter ");
          return;
        }
        // }
      })
      .catch((err) => {
        setLoader(false);
        alert(
          "Some Error occured try after sometime... if problem persists then contact us"
        );
        console.log(err);
      });

    // navigate
  };
  function processSubject(subject) {
    // Separate the '_GATE' suffix
    let baseSubject = subject.replace("_GATE", "");

    // Add spaces before each uppercase letter, except the first one
    let formattedSubject = baseSubject.replace(/([A-Z])/g, " $1").trim();

    return formattedSubject;
  }

  return (
    <div className="container">
      <div className="form-container">
        {/* Subject Filter */}
        <div className="filter-container">
          <div className="YearContent">Subject</div>
          <select
            name="subject"
            value={filters.subject}
            onChange={handleFilterChange}
          >
            <option className="optionSelector" value="">
              Select Subject
            </option>
            {subjects.map((subject) => (
              <option
                className="optionSelector"
                key={subject.Subject}
                value={subject.Subject}
              >
                {processSubject(subject.Subject)}
              </option>
            ))}
          </select>
        </div>

        {/* Chapter Filter */}
        <div className="filter-container">
          <div className="YearContent">Chapter</div>
          <select
            name="chapter"
            value={filters.chapter}
            onChange={handleFilterChange}
          >
            <option className="optionSelector" value="">
              Select Chapter
            </option>

            {chapters.map((chapter) => (
              <option
                className="optionSelector"
                key={chapter.ChapterID}
                value={chapter.ChapterID}
              >
                {/* {chapter.ChapterName?.replaceAll("GATE", "")
                  ?.split(" ")
                  ?.map((word, idx) => {
                    // If the word length is greater than 5, truncate and append "..."
                    if (idx > 4) {
                      return "";
                    }
                    return word.length > 5
                      ? word.substring(0, 5) + "..."
                      : word;
                  })
                  .join(" ")} */}
                {chapter.ChapterName?.replaceAll("GATE", "")?.length > 34
                  ? chapter.ChapterName?.replaceAll("GATE", "").substring(
                      0,
                      35
                    ) + "..."
                  : chapter.ChapterName?.replaceAll("GATE", "")}
              </option>
            ))}
          </select>
        </div>

        {/* Phase Filter */}

        {window.location.host?.toLowerCase()?.includes("gate") ? null : (
          <YearSelection
            filters={filters}
            setFilters={setFilters}
            years={phases}
            Name={"Phase"}
            phase={"phase"}
          />
        )}

        {/* Year Filter */}
        {console.log(filters)}
        {years?.length && chapters?.length ? (
          <YearSelection
            filters={filters}
            setFilters={setFilters}
            years={years}
            Name={"Year"}
            phase={"year"}
          />
        ) : null}

        {/* Attempted Filter */}
        {!(filters?.chapter && filters?.subject) ? null : (
          <div className="filter-container">
            <div className="YearContent">Attempted</div>
            <select
              name="attempted"
              value={filters.attempted}
              onChange={handleFilterChange}
            >
              <option className="optionSelector" value="">
                Select Attempted
              </option>
              {attemptedOptions.map((option) => (
                <option
                  className="optionSelector"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {pagingQuestions?.length ? (
        <div className="questions-container">
          <h2>
            {new Array(...selectedQuestions)?.length
              ? `${new Array(...selectedQuestions)?.length} Questions Selected`
              : "Select Questions"}
          </h2>
          <div className="select-all">
            <label className="select-all-checkbox">
              <input
                type="checkbox"
                checked={selectedQuestions.length === questions.length}
                onChange={handleSelectAll}
                className="select-all-checkbox-input"
              />
              {selectedQuestions.length === questions.length
                ? "Deselect All"
                : "Select All"}
            </label>
          </div>
          {/* Display list of questions */}
          <div className="questions-list">
            {pagingQuestions?.map((question, index) => (
              <ListingBoxer
                question={question}
                index={index}
                selectedQuestions={selectedQuestions}
                handleQuestionSelect={handleQuestionSelect}
              />
            ))}
          </div>
          {
            <div className="loadMoreBelt">
              {/* Pagination Controls */}
              <div
                className="pager-controls"
                style={{ gap: window?.innerWidth > 600.02 ? "" : "0.5em" }}
              >
                {currentPage > 1 ? (
                  <button
                    className="arrowsbtn"
                    onClick={() => goToPage(1)}
                    disabled={currentPage === 1}
                  >
                    &lt;&lt; {/* First page button */}
                  </button>
                ) : null}

                {currentPage > 1 ? (
                  <button
                    className="arrowsbtnSm"
                    onClick={() => goToPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    &lt; {/* Previous page button */}
                  </button>
                ) : null}

                {/* Page buttons with ellipsis */}
                <div className="paggerValues">
                  {getPaginationRange().map((page, index) => (
                    <button
                      key={index}
                      onClick={() =>
                        typeof page === "number" ? goToPage(page) : null
                      }
                      className={`PaggerBOx ${
                        typeof page === "number" && page === currentPage
                          ? "active"
                          : ""
                      }`}
                      disabled={typeof page !== "number"}
                    >
                      {page}
                    </button>
                  ))}
                </div>

                {currentPage < totalPages ? (
                  <button
                    className="arrowsbtnSm"
                    onClick={() => goToPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    &gt; {/* Next page button */}
                  </button>
                ) : null}

                {currentPage < totalPages ? (
                  <button
                    className="arrowsbtn"
                    onClick={() => goToPage(totalPages)}
                    disabled={currentPage === totalPages}
                  >
                    &gt;&gt; {/* Last page button */}
                  </button>
                ) : null}
              </div>
            </div>
          }
          <div style={{ height: "8em" }} />

          {/* Submit Button */}
          {selectedQuestions?.length ? (
            <div className="centerFixedFloat">
              {loader ? (
                <SyncLoader size={6} color="var(--text-primary-clr)" />
              ) : (
                <button className="app-btn-1" onClick={handleSubmit}>
                  Start Practice
                </button>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="NOQUESTIONMSG">
          No question available for this filter
        </div>
      )}
    </div>
  );
};

const YearSelection = ({ filters, setFilters, years, Name, phase }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleFilterChange = (year) => {
    setFilters((prevFilters) => {
      let updatedYears = [...prevFilters[phase]];

      if (updatedYears.includes(year)) {
        updatedYears = updatedYears.filter((item) => item !== year); // Remove year
      } else {
        updatedYears.push(year); // Add year
      }

      return { ...prevFilters, [phase]: updatedYears };
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <div className="filter-container">
      <div className="YearContent">{Name}</div>

      <div className="dropdown">
        <input
          type="text"
          value={filters[phase].join(", ") || `Select ${Name}`}
          readOnly
          className="dropdown-input"
          onClick={toggleDropdown}
        />
        {isDropdownOpen && (
          <div className="dropdown-options">
            {years.map((year) => (
              <div key={year.YearFilter} className="dropdown-item">
                <input
                  type="checkbox"
                  id={year.YearFilter}
                  checked={filters[phase].includes(year.YearFilter)}
                  onChange={() => handleFilterChange(year.YearFilter)}
                />
                <label htmlFor={year.YearFilter} style={{ marginBottom: 0 }}>
                  {year.YearFilter}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const MathjaxRender = ({ keywords, question }) => {
  const [stra, setStra] = useState("");
  const [strb, setStrb] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    let mstr = keywords;
    var m,
      urls = [],
      str = mstr,
      rex = /<img[^>]+src='?([^'\s]+)'?\s*\/>/g;
    while ((m = rex.exec(str))) {
      urls.push(m[1]);
    }

    setUrl(urls);

    let sta = mstr?.indexOf("<img");
    let end = mstr?.indexOf("/>");
    if (sta !== -1) {
      setStra(mstr?.substring(0, sta));
      setStrb(mstr?.substring(end + 2, mstr?.length));
    } else {
      setStra(mstr);
      setStrb("");
    }
    // setIsLoaded(true);
  }, [keywords]);

  return (
    <div className="tileDataMathjax">
      {" "}
      {stra && stra?.length ? (
        <MathJax dynamic>
          {stra}
          {/* {question?.Tags != "" &&
          !question?.Tags?.toLowerCase()?.includes("n/a") &&
          !question?.Tags?.toLowerCase()?.includes("theo") &&
          question?.Tags?.length && (
            <div className="pyQQuestionTag PYQQuestionBody">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7497 15.4993H4.24968C3.94218 15.4993 3.68718 15.2443 3.68718 14.9368C3.68718 14.6293 3.94218 14.3743 4.24968 14.3743H11.7497C12.0572 14.3743 12.3122 14.6293 12.3122 14.9368C12.3122 15.2443 12.0572 15.4993 11.7497 15.4993ZM14.2622 3.1393L11.2622 5.2843C10.8647 5.5693 10.2947 5.3968 10.1222 4.9393L8.70468 1.1593C8.46468 0.506797 7.54218 0.506797 7.30218 1.1593L5.87718 4.9318C5.70468 5.3968 5.14218 5.5693 4.74468 5.2768L1.74468 3.1318C1.14468 2.7118 0.349676 3.3043 0.597176 4.0018L3.71718 12.7393C3.82218 13.0393 4.10718 13.2343 4.42218 13.2343H11.5697C11.8847 13.2343 12.1697 13.0318 12.2747 12.7393L15.3947 4.0018C15.6497 3.3043 14.8547 2.7118 14.2622 3.1393ZM9.87468 10.0618H6.12468C5.81718 10.0618 5.56218 9.8068 5.56218 9.4993C5.56218 9.1918 5.81718 8.9368 6.12468 8.9368H9.87468C10.1822 8.9368 10.4372 9.1918 10.4372 9.4993C10.4372 9.8068 10.1822 10.0618 9.87468 10.0618Z"
                  fill="#D4AF57"
                />
              </svg>

              {question?.Tags}
            </div>
          )} */}
        </MathJax>
      ) : null}
      {strb && strb?.length ? <MathJax dynamic>{strb} </MathJax> : null}
    </div>
  );
};

const ListingBoxer = ({
  question,
  index,
  selectedQuestions,
  handleQuestionSelect,
}) => {
  // const [keyWords, setkeyWords] = useState(question.Question);
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      key={question.id}
      onMouseLeave={() => setExpanded(false)}
      className="question-item"
      onClick={() => [setExpanded(true), handleQuestionSelect(question.id)]}
      style={{
        background:
          question?.Attempted?.toLowerCase() === "yes"
            ? `linear-gradient(90deg, ${
                index % 2 === 0
                  ? "var(--accent-quinary-clr-fade0)"
                  : "transparent"
              } 0%, ${
                index % 2 === 0
                  ? "var(--accent-quinary-clr-fade0)"
                  : "transparent"
              } 70%, #334455 100%)`
            : index % 2 === 0
            ? "var(--accent-quinary-clr-fade0)"
            : "",
      }}
    >
      <div className="contenttableLeft">
        <label className="question-item">
          <input
            type="checkbox"
            id={question.QuestionID}
            checked={selectedQuestions.includes(question.QuestionID)}
            onChange={() => handleQuestionSelect(question.QuestionID)} // Make sure you handle checkbox state changes
            className="checkbox-input"
          />
          <span
            className="question-text"
            style={{
              textWrap: !expanded ? "nowrap" : "wrap",

              overflow: !expanded ? "hidden" : "auto",
              maxHeight: !expanded ? "3em" : "max-content",
            }}
          >
            {question?.RenderFormat != "html" ? (
              <MathjaxRender
                keywords={question?.Question}
                question={question}
              />
            ) : (
              <MathJax dynamic>
                <HTMLMathjaxRender text={question?.Question} />
              </MathJax>
            )}
          </span>
          <div
            className="namehidden"
            style={{
              transform: `rotate(${expanded ? "180" : "360"}deg)`,
            }}
          >
            <img src={arrowDownIcon} />
          </div>
        </label>
        <div className="btnContainerPracHub">
          {question?.Tags != "" &&
            !question?.Tags?.toLowerCase()?.includes("n/a") &&
            !question?.Tags?.toLowerCase()?.includes("theo") &&
            question?.Tags?.length && (
              <div className="PYQTAgGING">{question?.Tags}</div>
            )}
          <div
            className={`attemptedCheck ${
              question.Attempted === "Yes" ? "unattempted" : ""
            }`}
          >
            {question.Attempted === "Yes" ? "Attempted" : "New Ques."}
          </div>
        </div>
      </div>
    </div>
  );
};
