import PropTypes from "prop-types";
import "./FrameComponent1.css";

const FrameComponent1 = ({ className = "" }) => {
  return (
    <div className={`downlad-app-section ${className}`}>
      <div className="rocket-icon-parent">
        <img className="rocket-icon" alt="" src="/rocket-icon.svg" />
        <div className="your-ssc-journey-starts-here-parent">
          <div className="your-ssc-journey-container">
            <b>Your Exams Journey Starts Here :</b>
            <span className="span">{` `}</span>
          </div>
          <img className="seperator-icon1" alt="" src="/seperator1.svg" />
        </div>
        <i className="download-the-app">DOWNLOAD THE APP</i>
      </div>
      <div className="qr-code-and-image">
        <div className="mobile-mockup-images">
          <div className="image-2-parent">
            <div className="image-2">
              <img className="jugaad-icon" alt="" src="/jugaad@2x.png" />
            </div>
            <div className="image-1">
              <div className="jugaad-1-1-wrapper">
                <img className="jugaad-1-1" alt="" src="/jugaad-1-1@2x.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="qr-code">
          <img className="qr-code-icon" alt="" src="/qr-code.svg" />
          <b className="your-ssc-journey-container">
            <p className="scan-the-qr">SCAN THE QR CODE</p>
            <p className="scan-the-qr">DOWNLOAD THE APP</p>
          </b>
          <div className="available-on-both">
            Available on both iOS and Android
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
