const examsData = [
  {
    Heading: "College Admission Exam Practice",
    Exams: [
      {
        id: 1,
        name: "IIT-JEE",
        directlink: "https://iitjee.niopractice.com",
        comingSoon: false,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/JEE_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 2,
        name: "NEET",
        directlink: "https://neet.niopractice.com",
        comingSoon: false,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NEET_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 3,
        name: "CUET",
        directlink: "https://cuet.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/CUET_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 4,
        name: "SAT",
        directlink: "https://sat.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/SAT_BG.jpg",
        svg: "🌍", // Global
      },
      {
        id: 5,
        name: "CLAT",
        directlink: "https://clat.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/CLAT_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 6,
        name: "SPM",
        directlink: "https://spm.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/SPM_BG.jpg",
        svg: "MY", // Malaysia
      },
      {
        id: 7,
        name: "SPTM",
        directlink: "https://sptm.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/SPTM_BG.jpg",
        svg: "MY", // Malaysia
      },
      {
        id: 8,
        name: "Kỳ thi tốt nghiệp THPT",
        directlink: "https://thpt.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Vietnam_THPT_BG.jpg",
        svg: "VN", // Vietnam
      },
      {
        id: 9,
        name: "Mivhan Psychometric",
        directlink: "https://psychometric.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Psychometric_BG.jpg",
        svg: "IL", // Israel
      },
      {
        id: 10,
        name: "Common Test for University Admissions",
        directlink: "https://common-test.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/CommonTest_BG.jpg",
        svg: "JP", // Japan
      },
      {
        id: 11,
        name: "Suneung",
        directlink: "https://suneung.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Suneung_BG.jpg",
        svg: "KR", // South Korea
      },
    ],
  },
  {
    Heading: "Graduate School Exams Practice",
    Exams: [
      {
        id: 12,
        name: "CAT",
        directlink: "https://cat.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/CAT_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 13,
        name: "GRE",
        directlink: "https://gre.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/GRE_BG.jpg",
        svg: "🌍", // Global
      },
      {
        id: 14,
        name: "GATE",
        directlink: "https://gate.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/GATE_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 15,
        name: "GMAT",
        directlink: "https://gmat.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/GMAT_BG.jpg",
        svg: "🌍", // Global
      },
      {
        id: 16,
        name: "IIT-JAM",
        directlink: "https://iitjam.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/IIT-JAM_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 17,
        name: "LSAT",
        directlink: "https://lsat.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/LSAT_BG.jpg",
        svg: "US", // USA
      },
      {
        id: 18,
        name: "MCAT",
        directlink: "https://mcat.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/MCAT_BG.jpg",
        svg: "US", // USA
      },
    ],
  },
  {
    Heading: "Language Tests Practice",
    Exams: [
      {
        id: 19,
        name: "Spanish A1",
        directlink: "https://spanishA1.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Spanish_A1_BG.jpg",
        svg: "🌍", // Global
      },
      {
        id: 20,
        name: "Spanish A2",
        directlink: "https://spanishA2.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Spanish_A2_BG.jpg",
        svg: "🌍", // Global
      },
      {
        id: 21,
        name: "Spanish B1",
        directlink: "https://spanishB1.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Spanish_B1_BG.jpg",
        svg: "🌍", // Global
      },
    ],
  },
  {
    Heading: "Curriculum-aligned Practice",
    Exams: [
      {
        id: 22,
        name: "CBSE Class 12",
        directlink: "https://cbse12.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/CBSE_Class12_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 23,
        name: "IB Class 12",
        directlink: "https://ib12.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/IB_Class12_BG.jpg",
        svg: "🌍", // Global
      },
      {
        id: 24,
        name: "IGCSE Class 12",
        directlink: "https://igcse12.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/IGCSE_Class12_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 25,
        name: "Rajasthan Board",
        directlink: "https://rajasthanboard.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Rajasthan_Board_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 26,
        name: "MP Board",
        directlink: "https://mpboard.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/MP_Board_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 27,
        name: "UK A-levels",
        directlink: "https://ukalevels.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/UK_A_levels_BG.jpg",
        svg: "GB", // UK
      },
    ],
  },
  {
    Heading: "Banking and Railways Exams Practice",
    Exams: [
      {
        id: 28,
        name: "IBPS",
        directlink: "https://ibps.niopractice.com",
        comingSoon: false,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/IBPS_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 29,
        name: "RRB NTPC",
        directlink: "https://rrb.niopractice.com",
        comingSoon: false,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/RRB_NTPC_BG.jpg",
        svg: "IN", // India
      },
    ],
  },
  {
    Heading: "Civil Service Exams Practice",
    Exams: [
      {
        id: 30,
        name: "UPSC",
        directlink: "https://upsc.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/UPSC_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 31,
        name: "SSC",
        directlink: "https://ssc.niopractice.com",
        comingSoon: false,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/SSC_CGL_BG.jpg",
        svg: "IN", // India
      },
    ],
  },
  {
    Heading: "Olympiad Practice",
    Exams: [
      {
        id: 32,
        name: "P-RMO",
        directlink: "https://prmo.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/P-RMO_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 33,
        name: "NSET",
        directlink: "https://nset.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NSET_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 34,
        name: "NSEC",
        directlink: "https://nsec.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NSEC_BG.jpg",
        svg: "IN", // India
      },
    ],
  },
  {
    Heading: "Certifications Practice",
    Exams: [
      {
        id: 35,
        name: "Real Estate Licensing Exams",
        directlink: "https://realestate.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Real_Estate_Licensing_BG.jpg",
        svg: "US", // USA
      },
      {
        id: 36,
        name: "CTET",
        directlink: "https://ctet.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/CTET_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 37,
        name: "CA",
        directlink: "https://ca.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/CA_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 38,
        name: "CFA",
        directlink: "https://cfa.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/CFA_BG.jpg",
        svg: "🌍", // Global
      },
      {
        id: 39,
        name: "BAR Exams",
        directlink: "https://bar.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/BAR_BG.jpg",
        svg: "US", // USA
      },
    ],
  },
  {
    Heading: "Military Exams Practice",
    Exams: [
      {
        id: 40,
        name: "NDA",
        directlink: "https://nda.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/NDA_BG.jpg",
        svg: "IN", // India
      },
      {
        id: 41,
        name: "ASVAB",
        directlink: "https://asvab.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/ASVAB_BG.jpg",
        svg: "US", // USA
      },
    ],
  },
  {
    Heading: "Cognitive Aptitude Test Practice",
    Exams: [
      {
        id: 42,
        name: "Logical Reasoning",
        directlink: "https://logicalreasoning.niopractice.com",
        comingSoon: true,
        background:
          "https://niopracticevideos.s3.ap-northeast-1.amazonaws.com/Logical_Reasoning_BG.jpg",
        svg: "🌍", // Global
      },
    ],
  },
];
export default examsData;
