import PropTypes from "prop-types";
import "./Section1.css";

const Section1 = ({ className = "" }) => {
  return (
    <div className={`pyq-and-mocks ${className}`}>
      <div className="pyq-mock-tests-image">
        <img
          className="pyq-mock-tests-image1"
          alt=""
          src={
            window.location.host?.toLowerCase()?.includes("ssc")
              ? "/pyqmock-tests-image@2x.png"
              : window.location.host?.toLowerCase()?.includes("ibps")
              ? "/IBPS_Pyq_mock.png"
              : window.location.host?.toLowerCase()?.includes("gate")
              ? "/gate_Pyq_mock.png"
              : "/RRB_pyq_mock.png"
          }
        />
      </div>
      <div className="pyqs-mocks-features">
        <div className="heading1">
          <img className="frame-icon" alt="" src="/frame.svg" />
          <b className="pyqs-and-mock">PYQs and Mock Tests</b>
        </div>
        <div className="features1">
          <div className="frame-div">
            <div className="div3">•</div>
            <div className="access-100-pyqs">
              Attempt 100+ PYQs of past 5 years
            </div>
          </div>
          <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">
              Precisely developed Mock Tests with well versed latest pattern
            </div>
          </div>
          <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">Real Exam like interface</div>
          </div>
          <div className="parent1">
            <div className="div3">•</div>
            <div className="access-100-pyqs">
              Get detailed solutions for all the questions
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Section1.propTypes = {
  className: PropTypes.string,
};

export default Section1;
