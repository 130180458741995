import PropTypes from "prop-types";
import "./Section2.css";

const Section4 = ({ className = "" }) => {
  return (
    <div className={`test-analysis ${className}`}>
      <div className="test-analysis-features">
        <div className="heading2">
          <GATEPRACTICEHUB />
          <b className="personalized-test-analysis-container">
            <p className="personalized-test-analysis">
              Voice-Assisted Learning
            </p>

            <p className="subTopicsLandings">{`"AI Voice Chatbot for Smarter Learning"`}</p>
          </b>
        </div>
        <div className="features2">
          <div className="parent4">
            <div className="div7">•</div>
            <div className="get-detailed-analysis">
              Get real-time answers to your questions while practicing.
            </div>
          </div>
          <div className="parent5">
            <div className="div7">•</div>
            <div className="get-detailed-analysis">
              Understand concepts with instant, step-by-step explanations.
            </div>
          </div>
          <div className="parent5">
            <div className="div7">•</div>
            <div className="get-detailed-analysis">
              Stay engaged with hands-free, interactive learning support.
            </div>
          </div>
          {/* <div className="parent5">
            <div className="div7">•</div>
            <div className="get-detailed-analysis">
              Re-attempt the test to enhance your performence
            </div>
          </div> */}
        </div>
      </div>
      <div className="test-analysis-image">
        <img
          className="test-analysis-image1"
          alt=""
          src={
            window.location.host?.toLowerCase()?.includes("ssc")
              ? "/GateVoIce.png"
              : window.location.host?.toLowerCase()?.includes("ibps")
              ? "/GateVoIce.png"
              : window.location.host?.toLowerCase()?.includes("gate")
              ? "/GateVoIce.png"
              : "/GateVoIce.png"
          }
        />
      </div>
    </div>
  );
};

Section4.propTypes = {
  className: PropTypes.string,
};

export default Section4;

const GATEPRACTICEHUB = () => {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3333 21.8076H34.6666C42.208 21.8076 45.9813 21.8076 48.3226 24.1516C50.6666 26.4929 50.6666 30.2663 50.6666 37.8076C50.6666 45.3489 50.6666 49.1223 48.3226 51.4636C45.9813 53.8076 42.208 53.8076 34.6666 53.8076H32C32 53.8076 30.6666 59.1409 21.3333 59.1409C21.3333 59.1409 24 56.4503 24 53.7623C19.8586 53.6369 17.3866 53.1756 15.6773 51.4636C13.3333 49.1223 13.3333 45.3489 13.3333 37.8076C13.3333 30.2663 13.3333 26.4929 15.6773 24.1516C18.0186 21.8076 21.792 21.8076 29.3333 21.8076ZM50.6666 31.1409H52C54.4933 31.1409 55.7386 31.1409 56.6666 31.6769C57.2747 32.028 57.7796 32.5329 58.1306 33.1409C58.6666 34.0689 58.6666 35.3143 58.6666 37.8076C58.6666 40.3009 58.6666 41.5463 58.1306 42.4743C57.7796 43.0823 57.2747 43.5872 56.6666 43.9383C55.7386 44.4743 54.4933 44.4743 52 44.4743H50.6666M13.3333 31.1409H12C9.50664 31.1409 8.26131 31.1409 7.33331 31.6769C6.72529 32.028 6.22037 32.5329 5.86931 33.1409C5.33331 34.0689 5.33331 35.3143 5.33331 37.8076C5.33331 40.3009 5.33331 41.5463 5.86931 42.4743C6.22037 43.0823 6.72529 43.5872 7.33331 43.9383C8.26131 44.4743 9.50664 44.4743 12 44.4743H13.3333M36 9.80762C36 10.8685 35.5785 11.8859 34.8284 12.636C34.0782 13.3862 33.0608 13.8076 32 13.8076C30.9391 13.8076 29.9217 13.3862 29.1715 12.636C28.4214 11.8859 28 10.8685 28 9.80762C28 8.74675 28.4214 7.72933 29.1715 6.97919C29.9217 6.22904 30.9391 5.80762 32 5.80762C33.0608 5.80762 34.0782 6.22904 34.8284 6.97919C35.5785 7.72933 36 8.74675 36 9.80762ZM32 13.8076V21.8076V13.8076ZM24 32.4743V35.1409V32.4743ZM40 32.4743V35.1409V32.4743Z"
        fill="#EBEBEC"
      />
      <path
        d="M50.6666 31.1409H52C54.4933 31.1409 55.7386 31.1409 56.6666 31.6769C57.2747 32.028 57.7796 32.5329 58.1306 33.1409C58.6666 34.0689 58.6666 35.3143 58.6666 37.8076C58.6666 40.3009 58.6666 41.5463 58.1306 42.4743C57.7796 43.0823 57.2747 43.5872 56.6666 43.9383C55.7386 44.4743 54.4933 44.4743 52 44.4743H50.6666M13.3333 31.1409H12C9.50664 31.1409 8.26131 31.1409 7.33331 31.6769C6.72529 32.028 6.22037 32.5329 5.86931 33.1409C5.33331 34.0689 5.33331 35.3143 5.33331 37.8076C5.33331 40.3009 5.33331 41.5463 5.86931 42.4743C6.22037 43.0823 6.72529 43.5872 7.33331 43.9383C8.26131 44.4743 9.50664 44.4743 12 44.4743H13.3333M32 13.8076C33.0608 13.8076 34.0782 13.3862 34.8284 12.636C35.5785 11.8859 36 10.8685 36 9.80762C36 8.74675 35.5785 7.72933 34.8284 6.97919C34.0782 6.22904 33.0608 5.80762 32 5.80762C30.9391 5.80762 29.9217 6.22904 29.1715 6.97919C28.4214 7.72933 28 8.74675 28 9.80762C28 10.8685 28.4214 11.8859 29.1715 12.636C29.9217 13.3862 30.9391 13.8076 32 13.8076ZM32 13.8076V21.8076M24 32.4743V35.1409M40 32.4743V35.1409M29.3333 21.8076H34.6666C42.208 21.8076 45.9813 21.8076 48.3226 24.1516C50.6666 26.4929 50.6666 30.2663 50.6666 37.8076C50.6666 45.3489 50.6666 49.1223 48.3226 51.4636C45.9813 53.8076 42.208 53.8076 34.6666 53.8076H32C32 53.8076 30.6666 59.1409 21.3333 59.1409C21.3333 59.1409 24 56.4503 24 53.7623C19.8586 53.6369 17.3866 53.1756 15.6773 51.4636C13.3333 49.1223 13.3333 45.3489 13.3333 37.8076C13.3333 30.2663 13.3333 26.4929 15.6773 24.1516C18.0186 21.8076 21.792 21.8076 29.3333 21.8076Z"
        stroke="#2F99D7"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6666 44.4746C26.6666 44.4746 28.4453 45.8079 32 45.8079C35.5546 45.8079 37.3333 44.4746 37.3333 44.4746"
        fill="#EBEBEC"
      />
      <path
        d="M26.6666 44.4746C26.6666 44.4746 28.4453 45.8079 32 45.8079C35.5546 45.8079 37.3333 44.4746 37.3333 44.4746"
        stroke="#2F99D7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
