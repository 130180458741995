import React from "react";
// import Header from "./components/Header";
// import SSCCGL from "./components/SSCCGL";
import Section from "./components/Section";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import FrameComponent from "./components/FrameComponent";
import FrameComponent1 from "./components/FrameComponent1";
// import Footer from "./components/Footer";
import "./sscLandingPage.css";
import Footer from "../footer/Footer";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
function SSCLandingPage({ handleNavigate, subjectualData }) {
  return (
    <div>
      <div className="HeroSSC">
        <div
          className="hero-section"
          style={{ paddingBlock: "5em", marginBottom: "3em" }}
        >
          <div className="hero-section__container">
            <h1 className="hero-section__title">
              Turn Your{" "}
              {subjectualData?.SlugCh?.toLowerCase() === "ibps"
                ? "Bank PO"
                : subjectualData?.SlugCh?.toLowerCase() === "rrb"
                ? "Railway exam "
                : subjectualData?.SlugCh?.toLowerCase() === "gate"
                ? "GATE CSE "
                : subjectualData?.SlugCh}{" "}
              Goals into Reality
            </h1>
            <h2 className="hero-section__sub-title">
              Refine your{" "}
              <em className="hero-section__sub-title__accented-text">SKILLS</em>
              , Reach your{" "}
              <em className="hero-section__sub-title__accented-text">GOALS </em>
            </h2>
          </div>
          <div className="hero-section__button-container">
            <div className="ExamContainer-heroSec-ssc">
              <div className="ExamContainer-heroSec-ssc-inner">
                {window.location.host?.toLowerCase()?.includes("gate") ? (
                  <div
                    className="app-btn-1"
                    style={{ textWrap: "nowrap" }}
                    onClick={() => handleNavigate()}
                  >
                    Start Practicing
                  </div>
                ) : (
                  <div
                    className="ExamContainer-heroSec-ssc-inner-Main"
                    onClick={() => handleNavigate()}
                  >
                    {
                      <div className="ExamContainer-heroSec-ssc-contentFull-image">
                        <IconSSC />
                      </div>
                    }
                    <div className="ExamContainer-heroSec-ssc-contentFull-Txt">
                      <div className="ExamContainer-heroSec-ssc-contentFull-headTxt">
                        {window.location.host?.toLowerCase()?.includes("ssc")
                          ? "SSC CGL"
                          : window.location.host?.toLowerCase()?.includes("rrb")
                          ? "RRB NTPC"
                          : window.location.host
                              ?.toLowerCase()
                              ?.includes("gate")
                          ? "Start Practicing"
                          : "IBPS PO"}
                      </div>
                      {window.location.host?.toLowerCase()?.includes("ssc") ? (
                        <div className="ExamContainer-heroSec-ssc-contentFull-subTxt">
                          {window.location.host?.toLowerCase()?.includes("ibps")
                            ? "Institute of Banking Personnel Selection"
                            : window.location.host
                                ?.toLowerCase()
                                ?.includes("rrb")
                            ? "Railway Recruitment Board"
                            : window.location.host
                                ?.toLowerCase()
                                ?.includes("gate")
                            ? "Graduate Aptitude Test in Engineering"
                            : "Combined Graduate Level"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                {window.location.host?.toLowerCase()?.includes("ibps") ||
                window.location.host?.toLowerCase()?.includes("gate") ||
                window.location.host?.toLowerCase()?.includes("rrb") ? null : (
                  <>
                    <div
                      className="ExamContainer-heroSec-ssc-inner-Main"
                      onClick={() => handleNavigate()}
                    >
                      <div className="ExamContainer-heroSec-ssc-contentFull-image">
                        <IconSSC />
                      </div>
                      <div className="ExamContainer-heroSec-ssc-contentFull-Txt">
                        <div className="ExamContainer-heroSec-ssc-contentFull-headTxt">
                          SSC CHSL
                        </div>
                        <div className="ExamContainer-heroSec-ssc-contentFull-subTxt">
                          Combined Higher Secondary Level
                        </div>
                      </div>
                    </div>
                    <div
                      className="ExamContainer-heroSec-ssc-inner-Main"
                      onClick={() => handleNavigate()}
                    >
                      <div className="ExamContainer-heroSec-ssc-contentFull-image">
                        <IconSSC />
                      </div>
                      <div className="ExamContainer-heroSec-ssc-contentFull-Txt">
                        <div className="ExamContainer-heroSec-ssc-contentFull-headTxt">
                          SSC MTS
                        </div>
                        <div className="ExamContainer-heroSec-ssc-contentFull-subTxt">
                          Multi Tasking Staff
                        </div>
                      </div>
                    </div>
                    <div
                      className="ExamContainer-heroSec-ssc-inner-Main"
                      onClick={() => handleNavigate()}
                    >
                      <div className="ExamContainer-heroSec-ssc-contentFull-image">
                        <IconSSC />
                      </div>
                      <div className="ExamContainer-heroSec-ssc-contentFull-Txt">
                        <div className="ExamContainer-heroSec-ssc-contentFull-headTxt">
                          SSC CPO
                        </div>
                        <div className="ExamContainer-heroSec-ssc-contentFull-subTxt">
                          Central Police Organization
                        </div>
                      </div>
                    </div>{" "}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ssc-landing-page-final-auto">
        {/* <Header /> */}
        <div className="frame-parent">
          {/* <div className="turn-your-ssc-goals-into-reali-parent">
            <b className="turn-your-ssc">Turn Your SSC Goals into Reality</b>
            <div className="refine-your-skills-container">
              <span className="refine-your">{`Refine your `}</span>
              <i className="skills">SKILLS</i>
              <span className="refine-your">{`, Reach your `}</span>
              <i className="skills">GOALS</i>
            </div>
          </div>
          <div className="cgl-exams-section">
            <SSCCGL />
            <SSCCGL />
            <SSCCGL />
            <SSCCGL />
          </div> */}
          <Section />
          {/* <img className="dashed-seperator-icon" alt="" src /> */}
          <Separator />

          <Section1 />
          <Separator />
          <Section2 />
          <Separator />
          <Section3 />
          <Separator />
          <Section4 />
          {/* <Separator /> */}
          <FrameComponent />
          {window.location.host?.toLowerCase()?.includes("gate") ? null : (
            <FrameComponent1 />
          )}
          {/* <Footer /> */}
        </div>
      </div>

      {/* <div className="section-three">
        <img className="" src="/custom-test-icon.svg" alt="landing-img-4" />

        <div className="section-three__container">
          <div
            className="widthNumberification unFoldingErosion"
            style={{ padding: " 0.15em 0.6em" }}
          >
            3
          </div>
          <h2 className="section-three__title" style={{ color: "#2F99D7" }}>
            {" "}
            Finish practice sessions quickly and easily{" "}
          </h2>
          <p className="section-three__sub-title">
            Skip calculations and fly through practice sets
          </p>
        </div>
      </div> */}

      {/* <div className="frame-parent">
  
        <div className="section-1">
          <div className="frame-group">
            <div className="custom-test-icon-parent">
              <img
                className="custom-test-icon"
                alt=""
                src="/custom-test-icon.svg"
              />
              <div className="turn-your-ssc">
                <b>{`Create Your Own Custom Test `}</b>
                <i className="with-10000-questions">{`with 10,000+ Questions `}</i>
              </div>
            </div>
            <div className="frame-container">
              <div className="parent">
                <div className="div">•</div>
                <div className="create-exam-wise-custom">{`Create exam-wise custom test for  CGL, CHSL, MTS & CPO.`}</div>
              </div>
              <div className="group">
                <div className="div">•</div>
                <div className="evaluate-your-strengths">
                  Evaluate your strengths and weaknesses
                </div>
              </div>
              <div className="group">
                <div className="div">•</div>
                <div className="evaluate-your-strengths">
                  Get in-depth analysis of your custom tests
                </div>
              </div>
            </div>
          </div>
          <img
            className="custom-test-image"
            alt=""
            src="/custom-test-image@2x.png"
          />
        </div>
        <img className="dashed-seperator-icon" alt="" src />
        <div className="section-2">
          <img
            className="pyq-mock-tests-image"
            alt=""
            src="/pyqmock-tests-image@2x.png"
          />
          <div className="frame-parent1">
            <div className="custom-test-icon-parent">
              <img className="frame-icon" alt="" src="/frame.svg" />
              <b className="turn-your-ssc">PYQs and Mock Tests</b>
            </div>
            <div className="frame-parent3">
              <div className="parent1">
                <div className="div">•</div>
                <div className="access-100-pyqs">
                  Access 100+ PYQs of past 5 years
                </div>
              </div>
              <div className="parent2">
                <div className="div">•</div>
                <div className="access-100-pyqs">
                  Precisely developed Mock Tests with well versed latest pattern
                </div>
              </div>
              <div className="parent3">
                <div className="div">•</div>
                <div className="access-100-pyqs">Real Exam like interface</div>
              </div>
              <div className="parent4">
                <div className="div">•</div>
                <div className="access-100-pyqs">
                  Get detailed solutions for all the questions
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="dashed-seperator-icon1" alt="" src />
        <div className="section-3">
          <div className="frame-parent4">
            <div className="review-icon-parent">
              <img className="frame-child" alt="" src="/frame-96615275.svg" />
              <b className="turn-your-ssc">
                <p className="personalized-test-analysis">
                  Personalized Test Analysis
                </p>
                <p className="personalized-test-analysis">{`& Leaderboard`}</p>
              </b>
            </div>
            <div className="frame-parent6">
              <div className="parent5">
                <div className="div">•</div>
                <div className="evaluate-your-strengths">
                  Get detailed analysis of your attempted tests
                </div>
              </div>
              <div className="parent6">
                <div className="div">•</div>
                <div className="evaluate-your-strengths">
                  Find where you stand in leaderboard
                </div>
              </div>
              <div className="parent7">
                <div className="div">•</div>
                <div className="evaluate-your-strengths">
                  Unlimited Test Re-attempts
                </div>
              </div>
              <div className="parent8">
                <div className="div">•</div>
                <div className="evaluate-your-strengths">
                  Re-attempt the test to enhance your performence
                </div>
              </div>
            </div>
          </div>
          <img
            className="dashbord-image-icon"
            alt=""
            src="/dashbord-image@2x.png"
          />
        </div>
        <div className="testimonials-section">
          <div className="review-icon-parent">
            <img className="review-icon" alt="" src="/review-icon.svg" />
            <div className="what-students-say-about-us-parent">
              <b className="what-students-say">What students say about us</b>
              <img className="seperator-icon" alt="" src="/seperator.svg" />
            </div>
          </div>
          <div className="testimonials valuesToSSC">
            <div className="testimonial-1">
              <div className="testimonial-1-inner">
                <div className="frame-parent7">
                  <div className="jorem-ipsum-dolor-sit-amet-co-wrapper">
                    <div className="jorem-ipsum-dolor">
                      “Jorem ipsum dolor sit amet, conectetur adiscing elit.
                      Nunc vulutate libero et velit interdum, ac aliquet odio
                      mattis. Class aptent taciti sociosqu ad litora torquent
                      per conubia nostra, per inceptos himenaeos.”
                    </div>
                  </div>
                  <img className="frame-item" alt="" src="/vector-216.svg" />
                </div>
              </div>
              <div className="ellipse-parent">
                <img className="frame-inner" alt="" src="/ellipse-600@2x.png" />
                <div className="shalini-jain-parent">
                  <div className="shalini-jain">Shalini Jain</div>
                  <div className="ssc-cgl5">SSC CGL</div>
                </div>
              </div>
            </div>
            <div className="testimonial-2">
              <div className="frame-parent8">
                <div className="jorem-ipsum-dolor-sit-amet-co-container">
                  <div className="jorem-ipsum-dolor1">
                    “Jorem ipsum dolor sit amet, coectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu
                  </div>
                </div>
                <img className="frame-child1" alt="" src="/vector-214.svg" />
              </div>
              <div className="ellipse-group">
                <img className="frame-inner" alt="" src="/ellipse-601@2x.png" />
                <div className="ramesh-gupta">Ramesh Gupta</div>
                <div className="ssc-chsl1">SSC CHSL</div>
              </div>
            </div>
            <div className="testimonial-3">
              <div className="frame-parent9">
                <div className="jorem-ipsum-dolor-sit-amet-co-frame">
                  <div className="jorem-ipsum-dolor2">
                    “Jorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis. Class aptent taciti sociosqu
                  </div>
                </div>
                <img className="frame-child2" alt="" src="/vector-215.svg" />
              </div>
              <div className="ellipse-container">
                <img className="frame-inner" alt="" src="/ellipse-602@2x.png" />
                <div className="kailash-chaurasia-parent">
                  <div className="kailash-chaurasia">Kailash Chaurasia</div>
                  <div className="ssc-mts1">SSC MTS</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-parent10">
          <div className="rocket-icon-parent">
            <img className="rocket-icon" alt="" src="/rocket-icon.svg" />
            <div className="what-students-say-about-us-parent">
              <div className="your-ssc-journey-container">
                <b>Your SSC Journey Starts Here :</b>
                <span className="span">{` `}</span>
              </div>
              <img className="seperator-icon" alt="" src="/seperator1.svg" />
            </div>
            <i className="download-the-app">DOWNLOAD THE APP</i>
          </div>
          <div className="mobile-mockup-images-parent">
            <div className="mobile-mockup-images">
              <div className="image-2-parent">
                <div className="image-2">
                  <img className="jugaad-icon" alt="" src="/jugaad@2x.png" />
                </div>
                <div className="image-1">
                  <div className="jugaad-1-1-wrapper">
                    <img
                      className="jugaad-1-1"
                      alt=""
                      src="/jugaad-1-1@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="qr-code-section">
              <img className="qr-code-icon" alt="" src="/qr-code.svg" />
              <b className="turn-your-ssc">
                <p className="personalized-test-analysis">SCAN THE QR CODE</p>
                <p className="personalized-test-analysis">DOWNLOAD THE APP</p>
              </b>
              <div className="available-on-both">
                Available on both iOS and Android
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

const IconSSC = () => {
  return (
    <img
      className="ssc-cgl-logo1-7-icon"
      alt=""
      src={
        window.location.host?.toLowerCase()?.includes("ssc")
          ? "/ssc-cgl-logo1-7@2x.png"
          : window.location.host?.toLowerCase()?.includes("ibps")
          ? "/ibps_logo.png"
          : window.location.host?.toLowerCase()?.includes("gate")
          ? "/gate_logo.png"
          : "/rrb_logo.png"
      }
      style={{
        maxHeight: window?.location?.host?.toLowerCase()?.includes("ibps")
          ? "100%"
          : "",
      }}
    />
    // <svg
    //   width="124"
    //   height="100"
    //   viewBox="0 0 124 100"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g>
    //     <rect width="124" height="100" fill="#fff" />
    //   </g>
    //   <defs>
    //     <pattern
    //       id="pattern0_5238_34261"
    //       patternContentUnits="objectBoundingBox"
    //       width="1"
    //       height="1"
    //     >
    //       <use
    //         // xlink:href="#image0_5238_34261"
    //         transform="matrix(0.00198777 0 0 0.00244131 -0.282569 -0.2)"
    //       />
    //     </pattern>
    //     <image id="image0_5238_34261" width="777" height="780" />
    //   </defs>
    // </svg>
  );
};
const Separator = () => {
  return (
    <svg
      className="dashed-seperator-icon"
      viewBox="0 0 800 1"
      // height="1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="0.5"
        x2="800"
        y2="0.5"
        stroke="#F8219F"
        stroke-dasharray="10 10"
      />
    </svg>
  );
};
export default SSCLandingPage;
