import axios from 'axios'
const options = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
};
const getPremiumData = async (token) => {
    const apiUrl = `https://yk1u0dvdjl.execute-api.ap-northeast-1.amazonaws.com/buyProduct/buyProduct?token=${token}`

    try {
        const response = await axios.get(apiUrl, options);
        return response
    } catch {
        throw new Error("An error occurred during the API request.");
    }

}

export default getPremiumData