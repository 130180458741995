import axios from "axios";
const options = {
  headers: {
    "Content-Type": "application/json",
  },
};
const getAndInsertQuestionsSelected = async (UrlString, body) => {
  const apiUrl = `https://hy46guxf0j.execute-api.ap-northeast-1.amazonaws.com/practiceHubData?${UrlString}`;

  try {
    const response = await axios.post(apiUrl, body, options);
    return response;
  } catch {
    throw new Error("An error occurred during the API request.");
  }
};

export default getAndInsertQuestionsSelected;
