import axios from "axios";
const options = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
const getPracticeHubData = async (UrlString) => {
  const apiUrl = `https://kvoenor571.execute-api.ap-northeast-1.amazonaws.com/practiceHubFilter?${UrlString}`;

  try {
    const response = await axios.get(apiUrl, options);
    return response;
  } catch {
    throw new Error("An error occurred during the API request.");
  }
};

export default getPracticeHubData;
